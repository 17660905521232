<template>
  <validation-observer ref="formCustomers">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[2].text }}</h3>
          <b-row class="mt-2 justify-content-between">
            <b-col
              md="6"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Nama Barang</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.name"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Kode Barang</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.no"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Stok</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.availableToSell"
                    disabled
                  />
                </b-col>
              </b-row>
              <!-- <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Satuan</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.unit"
                    disabled
                  />
                </b-col>
              </b-row> -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Harga Jual</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.unitPrice"
                    disabled
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="false"
              md="5"
            >
              <b-row
                class="my-1"
              >
                <b-col sm="5">
                  <div class="image-input">
                    <validation-provider
                      #default="{ errors }"
                      name="Photo"
                      :rules="vars.rulesImage"
                    >
                      <b-img
                        :src="getUrlOfFile(vars.photo, true)"
                        class="mb-2"
                      />
                      <label
                        v-if="!isDetailPage"
                        for="file-input"
                      >
                        <input
                          id="file-input"
                          type="file"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          hidden
                          @change="AddPhoto"
                        >
                        <feather-icon icon="PlusIcon" />
                        Upload Photo
                      </label>
                      <input
                        v-model="vars.photo"
                        type="hidden"
                        :state="errors.length > 0 ? false:null"
                      >
                      <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>
                    </validation-provider>

                  </div>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col cols="12">
                  <label
                    class="h5"
                    for="note"
                  >Deskripsi</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Deskripsi"
                    rules="required"
                  >
                    <b-form-textarea
                      id="note"
                      v-model="models.description"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mt-3">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded mx-md-1 my-1 my-md-0"
              @click="$router.push({path: config.uri})"
            >
              Kembali
            </b-button>
            <!-- <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded"
            >
              Simpan
            </b-button> -->
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BImg, BFormInput, BFormTextarea, BCollapse,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, image,
} from '@validations'
import api from '@/utils/api'

// eslint-disable-next-line import/no-cycle
import { getUrlOfFile, uploadFile } from '@/utils/helpers'

export default {
  name: 'FormInventoryRaw',
  metaInfo: {
    title: 'Form Bahan Baku',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    BImg,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BCollapse,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        id: this.$route.params.id,
        detailItemImage: [],
        description: '',
      },
      vars: {
        photos: [],

        name: '',
        no: '',
        availableToSell: '',
        unitPrice: '',
      },
      // Functions
      getUrlOfFile,
      required,
      image,
    }
  },
  computed: {
    isEditCustomerPage() {
      return this.$route.name === 'edit-customer'
    },
    isDetailPage() {
      return this.$route.name === 'detail-bahan-baku'
    },
    imageField() {
      return this.vars.photo
    },
  },
  watch: {
    imageField() {
      if (typeof this.vars.photo !== 'string') {
        this.vars.rulesImage = 'required|image'
      }
    },
  },
  created() {
    this.getById()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.rawMaterial}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.vars.name = data.name
            this.vars.no = data.no
            this.vars.availableToSell = data.availableToSell
            this.vars.unitPrice = data.unitPrice
            // this.vars.unit = data.unit
            this.vars.unitPriceFormat = data.unitPriceFormat
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },

    AddPhoto(event) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      if (event.target.files[0].size < fileSizeLimit) {
        const photo = event.target.files[0]
        this.vars.photo = photo
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },

    save() {
      this.$refs.formCustomers.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          // get file image url
          this.models.photo = typeof this.vars.photo === 'string' ? this.vars.photo : await uploadFile(this.vars.photo)

          if (!this.$route.params.id) {
            request = this.$axios.post(api.customers, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.customers}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} customer`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
